import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import styled from 'styled-components';

import { useUserContext } from '../../../contexts/UserContext';
import { useLazyGetAIMotivationLetter } from '../../../graph/queries/getMotivationLetter';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { Copy } from '../../atoms/Typography';
import { SmartTextInput } from '../../atoms/Form/SmartTextInput';
import { Job, Maybe } from '../../../generated/types';
import { ChevronRightIcon } from '../../atoms/Icons';
import { Colors } from '../../../styles/colors';

export const ApplyJobModal = ({ job, handleCancel, handleSubmit }: InviteUserOnBoardModalProps) => {
  const [getAIMotivationLetter] = useLazyGetAIMotivationLetter();
  const user = useUserContext();
  const jobTitle = job?.jobTitle || '';
  const companyName = job?.company || '';
  const companyInformation = job?.companyInfo || '';
  const contactDetails = job?.contacts?.[0]?.fullName || '';

  const jobDescription = job?.jobDescription || '';
  const jobRequirements = job?.jobRequirement || '';

  const onSubmit: SubmitHandler<FormData> = async () => {
    handleSubmit();
  };

  return (
    <Wrapper>
      <H2>Apply</H2>
      <InfoAlert>
        <Copy marginBottom={16}>You are in the process of applying for the job position.</Copy>
        <Copy styleLevel={2} marginBottom={16}>
          Seeking assistance with your cover/application letter? Explore the AI Documents section for more details. It's
          worth noting that this feature is exclusively available to our Premium members.
        </Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
            Apply
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
`;

const validationSchema = Yup.object().shape({});

type FormData = Yup.InferType<typeof validationSchema>;

interface InviteUserOnBoardModalProps {
  job?: Maybe<Job> | undefined;
  handleSubmit: () => void;
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
