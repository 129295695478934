import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryAiMotivationLetterArgs } from '../../generated/types';

export const GET_AI_MOTIVATION_LETTER = gql`
  query AIMotivationLetter($data: AIMotivationLetter!) {
    aiMotivationLetter(data: $data)
  }
`;

export const useGetAIMotivationLetter = (
  options?: QueryHookOptions<Pick<Query, 'aiMotivationLetter'>, QueryAiMotivationLetterArgs>
) => useQuery<Pick<Query, 'aiMotivationLetter'>, QueryAiMotivationLetterArgs>(GET_AI_MOTIVATION_LETTER, options);

export const useLazyGetAIMotivationLetter = (
  options?: QueryHookOptions<Pick<Query, 'aiMotivationLetter'>, QueryAiMotivationLetterArgs>
) => useLazyQuery<Pick<Query, 'aiMotivationLetter'>, QueryAiMotivationLetterArgs>(GET_AI_MOTIVATION_LETTER, options);
